<template >
  <el-card>
    <div class="manageItem" @click="enterColleague">
        <i class="el-icon-s-help"></i>
        <div class="text">同事管理</div>
    </div>
    <!-- <div class="manageItem" @click="enterExp">
        <i class="el-icon-s-help"></i>
        <div class="text">虚拟实验管理</div>
    </div> -->
  </el-card>
  
</template>

<script>
    // 引入试题列表js
    import navigation from "@/assets/js/teacher/other/navigation.js";
    export default {
     ...navigation
    }
</script>

<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/teacher/other/navigation.scss";
</style>