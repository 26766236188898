import { request } from "@/request/http";

export default {
  getColleagueList: (data) => {
    return request("POST", `/index.php/backother/getColleagueList`, data);
  },

  searchColleague: (data) => {
    return request("GET", `/index.php/project/searchTogether`, data);
  },

  addColleague: (data) => {
    return request("POST", `/index.php/backother/addColleague`, data);
  },

  delColleague: (data) => {
    return request("POST", `/index.php/backother/delColleague`, data);
  },
};