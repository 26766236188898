import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/teacher/other/colleague.js';
export default {
    // 页面名称
    name: "colleague",
    data() {
        return {
        }
    },

    methods:
    {
        enterColleague() {
            let self = this;
            // self.$router.push(path)
            window.open("#/teacher/other/colleague")
        },
        enterExp(path) {
            let self = this;
            // self.$router.push(path)
            window.open("#/teacher/other/expManager")
        },
    }
};